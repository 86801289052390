<template>
  <div class="text-center">
    <!-- https://vuetifyjs.com/en/api/v-dialog/ -->
    <v-dialog v-model="enabled" :width="width || 300" @keydown.esc="$emit('close')" :transition="transition || 'dialog-transition'"> 
      <v-card :height="height || 350" color="primary"> 
        <v-card-title v-if="title" class="headline mb-3">{{ title }}<v-spacer></v-spacer><v-icon class="ml-5" @click="$emit('close')" color="white">mdi-close</v-icon></v-card-title>
        <v-card-text v-if="message" class="mt-3">{{ message }}</v-card-text>
        <v-spacer></v-spacer>
        <slot/>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'Dialog',
  data: () => ({
    enabled: true
  }),
  props: {
    height: Number,
    message: String,
    title: String,
    timeout: Number,
    width: Number,
    transition: String
  },
  mounted () {
    this.timeout > 0 && setTimeout(() => this.$emit('close'), this.timeout)

    window.addEventListener('click', e => {
      if (e.target.className === 'v-overlay__scrim')
        this.$emit('close')
    })
  }
}
</script>
<style lang="scss" scoped>
  .v-card__title { 
    font-size: 25px !important;
    text-shadow: 1px 1px 12px black;
    color: #ffcb00 !important; 
    font-family: 'Pacifico', cursive !important;
  }
</style>